export const UPDATE_BOARDS = "UPDATE_BOARDS";
export const UPDATE_FEEDS = "UPDATE_FEEDS";
export const UPDATE_LETTERS = "UPDATE_LETTERS";
export const UPDATE_CONTACTS = "UPDATE_CONTACTS";
export const UPDATE_USER_CONTACTS = "UPDATE_USER_CONTACTS";
export const USER_DATA = "USER_DATA";
export const BOARD_DATA = "BOARD_DATA";
export const COMPANIES_DATA = "COMPANIES_DATA";
export const CONTACTS_DATA = "CONTACTS_DATA";
export const ALL_ACTIVITY_DATA = "ALL_ACTIVITY_DATA";
export const UPDATE_MODULES = "UPDATE_MODULES";
export const DATA_DATA = "DATA_DATA";
export const DELETE_FEED = "DELETE_FEED";
export const START_SYNC = "START_SYNC";
export const STOP_SYNC = "STOP_SYNC";
// export const LANES_DATA = "LANES_DATA";
