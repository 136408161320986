import welcomeContent from './welcomeContent.js';


const WelcomeData = [
   {
       _id: "m00",
       moduleNumber : 0,
       moduleTitle : "Welcome",
       moduleOverview : welcomeContent,
       moduleCategory : "",
       modulePoster : "contacts.jpg",
       moduleLink :"contacts",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_af32017b24e44c369b34814434db9d4b/1080p/mp4/file.mp4",
   },
   {
       _id: "m0",
       moduleNumber : 1,
       moduleTitle : "My Contacts",
       moduleOverview : "<p>Store your professional network in My Contacts. Organize your connections, and customize details for each.</p>",
       moduleCategory : "",
       modulePoster : "contacts.jpg",
       moduleLink :"contacts",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_aba40ff548ec4e398fcce61c6ac75409/1080p/mp4/file.mp4",
   },
   {
       _id: "m1",
       moduleNumber : 2,
       moduleTitle : "My Companies",
       moduleOverview : "<p>Research and track target companies in My Companies. Add custom notes, application status, contacts at each company, and more. Keep all your prospect information organized in one spot.</p>",
       moduleCategory : "",
       modulePoster : "companies.jpg",
       moduleLink :"companies",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_4020b3d544194ce1bcb598341e11cc26/1080p/mp4/file.mp4",
   },
   {
       _id: "m2",
       moduleNumber : 3,
       moduleTitle : "My Boards",
       moduleOverview : "<p>With My Boards, you can create customized Kanban boards to track your job search. Simply drag and drop job cards into different workflow columns to update status. Easily track your progress on a visual board.</p>",
       moduleCategory : "",
       modulePoster : "boards.jpg",
       moduleLink :"boards",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_c3fd4944e2814d34a057b3dc3c8d4a5c/1080p/mp4/file.mp4",
   },
   {
       _id: "m3",
       moduleNumber : 4,
       moduleTitle : "My Calendar",
       moduleOverview : "<p>The My Calendar feature integrates with your Google Calendar, automatically syncing any job search activities and events you schedule. Get notifications and reminders for interviews, applications, and more.</p>",
       moduleCategory : "",
       modulePoster : "calendar.jpg",
       moduleLink :"calendar",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_c9c51ded06f74ad88efa01cc7a3ec26b/1080p/mp4/file.mp4",
   },
   {
       _id: "m4",
       moduleNumber : 5,
       moduleTitle : "My Activity",
       moduleOverview : "<p>My Activities gives you a big picture overview of all your job search tasks in one place. Sort and filter by date, priority, or status to stay organized and on top of next steps.</p>",
       moduleCategory : "",
       modulePoster : "activity.jpg",
       moduleLink :"activity",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_a37f224220e14bc9a97ccca25810575e/1080p/mp4/file.mp4",
   },
   {
       _id: "m5",
       moduleNumber : 6,
       moduleTitle : "Feeds",
       moduleOverview : "<p>Feeds can offer you the latest job listings, matched to the requirements you ask. Stay on pulse.</p>",
       moduleCategory : "",
       modulePoster : "feeds.jpg",
       moduleLink :"feeds",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_31787ef1b8614ba2a6d796c24beac64b/1080p/mp4/file.mp4",
   },
   {
       _id: "m6",
       moduleNumber : 7,
       moduleTitle : "My Letters",
       moduleOverview : "<p>Create customized email and letter templates in My Letters. Access and edit cover letters, follow ups, introductions, and more to reuse with a personal touch.</p>",
       moduleCategory : "",
       modulePoster : "letters.jpg",
       moduleLink :"letters",
       moduleVideo :"https://video.wixstatic.com/video/9076b6_3d0115e2e838429696caeeb9af19c749/1080p/mp4/file.mp4",
   },
   {
     _id: "m7",
     moduleNumber : 8,
     moduleTitle : "My Metrics",
     moduleOverview : "<p>My Metrics provides a way to visualize your efforts with data on your progress.</p>",
     moduleCategory : "",
     modulePoster : "metrics.jpg",
     moduleLink :"metrics",
     moduleVideo :"https://video.wixstatic.com/video/9076b6_540bb94243f6451f943f06b7758d8932/1080p/mp4/file.mp4",
 },
 ];


export default WelcomeData;