import { useReducer } from 'react'

import {
  UPDATE_BOARDS,
  UPDATE_CONTACTS,
  UPDATE_FEEDS,
  UPDATE_LETTERS,
  UPDATE_USER_CONTACTS,
  USER_DATA,
  BOARD_DATA,
  COMPANIES_DATA,
  CONTACTS_DATA,
  UPDATE_MODULES,
  ALL_ACTIVITY_DATA,
  DELETE_FEED,
  START_SYNC,
  STOP_SYNC,
} from '../utils/actions'


export const reducer = (state, action) => {
  switch (action.type) {
    // if action type value is the value of `UPDATE_BOARDS`, return a new state object with an updated boards array
    case UPDATE_BOARDS:
      return {
        ...state,
        boards: [...action.boards],
      }
    case UPDATE_FEEDS:
      return {
        ...state,
        feeds: [...state.feeds, action.board],
      }
    case UPDATE_LETTERS:
      return {
        ...state,
        letters: [...action.letters],
      }
    case UPDATE_CONTACTS:
      return {
        ...state,
        contacts: [...action.contacts],
      }
    case UPDATE_USER_CONTACTS:
      return {
        ...state,
        usercontacts: [...action.usercontacts],
      }
    case DELETE_FEED:
      const updatedUserfeeds = state.userdata.userfeeds.filter(feed => feed._id !== action.payload);
      return {
        ...state,
        userdata: {
          ...state.userdata,
          userfeeds: updatedUserfeeds,
        },
      };
    case USER_DATA:
      return {
        ...state,
        userdata: action.userdata,
      }
    case BOARD_DATA:
      return {
        ...state,
        boarddata: action.boarddata,
      }
    case COMPANIES_DATA:
      return {
        ...state,
        companiesdata: action.companiesdata,
      }
    case CONTACTS_DATA:
      return {
        ...state,
        contactsdata: action.contactsdata,
      }
    case ALL_ACTIVITY_DATA:
      return {
        ...state,
        activitydata: action.activitydata,
      }
    case UPDATE_MODULES:
      return {
        ...state,
        modules: [...action.modules],
      }
    case START_SYNC:
      return {
        ...state,
        isSyncing: true,
      };
    case STOP_SYNC:
      return {
        ...state,
        isSyncing: false,
      };


    default:
      return state ? state : ''
  }
}

export function usePepperReducer(initialState) {
  return useReducer(reducer, initialState)
}
