import React , { useState } from 'react';
import { Modal, Tab, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './modal.scss';

const ModalTutorial = (props) => {
  const { data } = props;
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const handleVideoClick = (videoUrl) => {
    setVideoUrl(videoUrl); // Set the video URL to be displayed in the modal
    setVideoModalVisible(true); // Show the modal
  };

  const heading = {
    title: "Welcome to Peppercorn Plan"
  }

    return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
      >

        <Modal.Header closeButton>
          -
        </Modal.Header>
        <Modal.Body>
        <h3 className="py-4">{heading.title}</h3>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
          <Row>
            <Col style={{ textAlign: 'justify' }} sm={3}>
              <Nav variant="pills" className="flex-column">
              {data.map((module, index) => (
                <Nav.Item key={index}>
                <Nav.Link eventKey={index}>
                  {module.moduleTitle}
                </Nav.Link>
                </Nav.Item>
              ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
              {data.map((module, index) => (
                <Tab.Pane eventKey={index} key={index}>
                  <div className="modal-video-thumbnail-container">
                      <img
                        className="pt-4 modal-video-thumbnail"
                        src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`} // Use module.modulePoster for the thumbnail image URL
                        alt={`module-video-thumbnail:${module.moduleTitle}`}
                        onClick={() => handleVideoClick(module.moduleVideo)} // Handle video click event   Use module.moduleVideo as the video URL
                      />
                      <div className="modal-video-play-button" onClick={() => handleVideoClick(module.moduleVideo)}>
                        <span>&#9654;</span>
                      </div>
                  </div>
                  <article style={{ textAlign: 'justify' }} dangerouslySetInnerHTML= {{__html: module.moduleOverview}} />
                  <Link className="d-flex justify-content-center py-2 px-2" to={`/${module.moduleLink}`}>
                    <button className="btn-modal">Go to {module.moduleTitle}</button>
                  </Link>
                </Tab.Pane>
              ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        </Modal.Body>
      </Modal>
      <Modal show={videoModalVisible} onHide={() => setVideoModalVisible(false)} size="lg">
        <Modal.Header closeButton>
          -
        </Modal.Header>
      <Modal.Body>
        <video width="100%" controls>
          <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
    </div>
    );
  };

export default ModalTutorial;