// use this to decode a token and get the user's information out of it
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

// create a new class to instantiate for a user
class AuthService {
  // get user data
  getProfile() {
    return this.getToken() ? jwtDecode(this.getToken()) : null;
  }

  // verify a valid token
  verifyToken(token) {
    const decoded = jwtDecode(token);
    return decoded;
  }

  // check if user's logged in
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const getSignInCookie = Cookies.get('signin');
    if ((getSignInCookie === 'False') || (getSignInCookie === 'false')) {
      return false;
    }
    return true;
  }

  // check if user's logged in via Google
  googleLogged() {
    // Checks if there is a saved token and it's still valid
    const getSignInCookie = Cookies.get('googleToken');
    if (!getSignInCookie) {
      return false;
    }
    return true;
  }

  login(idToken) {
    // Saves user token to localStorage
    Cookies.set('id_token', idToken);
    // Retrieves referring location from localStorage
    const referring_url = localStorage.getItem('referring_url') || window.location.origin;
    window.location.assign(referring_url);
    localStorage.removeItem('referring_url');
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('referring_url');
    // this will reload the page and reset the state of the application
    window.location.assign('/');
  }

  // Get the user token from cookies
  getToken() {
    return Cookies.get('id_token');
  }
}

export default new AuthService();
