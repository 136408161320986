import { useQuery } from '@apollo/client';
import React, { useState, createContext, useContext, useEffect } from 'react';
import Auth from './auth';
import { QUERY_ME } from './queries';


const AuthContext = createContext();

const AuthContextProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { loading: userLoading, data: userData } = useQuery(QUERY_ME)
  
  useEffect(() => {
    
    if (userData) {
      document.cookie = 'signin=True';
      setIsAuthenticated(true);
    } else if (!userLoading) {
      document.cookie = 'signin=False';
      setIsAuthenticated(false)
    }
  }, [userData, userLoading])
  
  const toggleAuth = () => {

    if(Auth.loggedIn()){
        setIsAuthenticated(true);
    } else {
        setIsAuthenticated(false)
    }
  };

  return <AuthContext.Provider value={{ isAuthenticated, toggleAuth }} {...props} />;
};

const useAuthContext = () => {
  return useContext(AuthContext)
}

export { AuthContextProvider, useAuthContext };