import { gql } from '@apollo/client'

export const BOARD_DETAIL = gql`
  fragment boarddetails on board {
    _id
    boardName
    isArchived
    lanes {
      _id
      cards
      listIcon
      title
    }
    userActivity {
      _id
      activityTitle
    }
    usernote {
      _id
      noteText
    }
  }
`
export const FEED_DETAIL = gql`
  fragment feeddetails on feed {
    _id
    feedName
    emoji
    isPublic
    employeeRangeMin
    employeeRangeMax
    roles
    cities
    employers
    tags
  }
`
export const CONTACT_DETAIL = gql`
  fragment contactdetails on contact {
    _id
    firstName
    lastName
    profilePic
    gender
    title
    role
    seniority
    location
    city
    state
    country
    linkedIn
    twitter
    crunchbase
    facebook
    github
    bio
    about
    usercompanies {
      _id
      companyName
      companyUrl
      companyLocation
      companySize
      companyDescription
      companyFoundedYear
    }
    companies {
      _id
      companyName
      companyUrl
      companyLocation
      companySize
      companyDescription
      companyFoundedYear
    }
  }
`

export const USERCONTACT_DETAIL = gql`
  fragment usercontactdetails on usercontact {
    _id
    firstName
    lastName
    profilePic
    gender
    title
    role
    seniority
    location
    city
    state
    country
    linkedIn
    twitter
    crunchbase
    facebook
    github
    bio
    about
    usercompany {
      _id
      companyName
      companyUrl
      companyLocation
      companySize
      companyDescription
      companyFoundedYear
    }
    company {
      _id
      companyName
      companyUrl
      companyLocation
      companySize
      companyDescription
      companyFoundedYear
    }
  }
`
export const COMPANY_DETAIL = gql`
  fragment companydetails on company {
    _id
    companyName
    companyUrl
    companyLocation
    companyFoundedYear
    companyDomain
    companyDescription
    companySize
    companyCulture
    companyValues
    contacts {
      _id
    }
    usercontacts {
      _id
    }
  }
`

export const LETTER_DETAIL = gql`
  fragment letterdetails on letter {
    _id
    letterName
    letterCategory
    letterContent
  }
`
export const ACTIVITY_DETAIL = gql`
  fragment activitydetails on activity {
    _id
    activityTitle
    activityNote
    activityCategory
    activityCompleted
    startAt
    endAt
    cards
    boardId
    companyId
    userCompany
  }
`

export const JOB_DETAIL = gql`
  fragment jobdetails on job {
    _id
    actionType
    createdByWorkflow
    title
    jobLocation
    jobSalary
    jobUrl
    jobUrn
    description
    deadline
    color
    jobnote
    companyId {
      _id
      companyName
      companyUrl
    }
    userCompany {
      _id
      companyName
      companyUrl
    }
  }
`

export const ACTION_DETAIL = gql`
  fragment actiondetails on action {
    _id
    actionType
    createdByWorkflow
    cards
    boardId
    noteId
    companyId
    userCompany
    activityId
    userId
    contact
    userContact
  }
`
