import React, { createContext, useContext} from 'react'
import { usePepperReducer } from './reducers'

const PepperContext = createContext()
const { Provider } = PepperContext

const PepperProvider = ({ value = [], ...props }) => {
  const [state, dispatch] = usePepperReducer({
    userdata: [],
    boarddata: [], //data in one board
    boards: [], // array of all boards
    feeds: [], // array of all feeds inside of a module
    usercontacts: [],
    contacts: [],
    letter: [],
    search: [],
    modules: [], // array of all modules
    datadata: [], //hardcoded data in one board
    isAuthentication: false,
    isSyncing: false,
    // lanesdata: [],
  })
  // console.log({state}); // comment this in to test!
  return <Provider value={[state, dispatch]} {...props} />
}

const usePepperContext = () => {
  return useContext(PepperContext)
}

export { PepperProvider, usePepperContext }
