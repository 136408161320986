import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './modal.scss';
import WelcomeData from '../data/WelcomeData';
import { Modal, Tab, Row, Col, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './modal.scss';

const ModalTutorial1 = (props) => {
  const data = WelcomeData;
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const handleVideoClick = (videoUrl) => {
    setVideoUrl(videoUrl); // Set the video URL to be displayed in the modal
    setVideoModalVisible(true); // Show the modal
  };

  const heading = {
    title: "Welcome to Peppercorn Plan"
  };

  const nextModule = () => {
    if (currentModuleIndex < data.length - 1) {
      setCurrentModuleIndex(currentModuleIndex + 1);
    }
  };

  const closeModal = () => {
    setCurrentModuleIndex(0);
    props.onHide(); // Close the modal

    // Redirect the user to the desired location
    navigate('/'); // Change '/` to your desired URL
  };
  const isLastModule = currentModuleIndex === data.length - 1;

  return (
  <div>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center"
    >
      <Modal.Body>
        <h3 className="py-4">{heading.title}</h3>
        <Tab.Container id="left-tabs-example" activeKey={currentModuleIndex.toString()}>
          <Row>
            <Col style={{ textAlign: 'justify' }} sm={3}>
              <Nav variant="pills" className="flex-column">
                {data.map((module, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index.toString()}>
                      {module.moduleTitle}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <div className="py-4 d-flex justify-content-between align-items-center">
          {!isLastModule && (
            <button className="btn-modal" onClick={nextModule}>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
          {isLastModule && (
            <button className="btn-modal" onClick={closeModal}>
              Close
            </button>
          )}
        </div>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {data.map((module, index) => (
                  <Tab.Pane eventKey={index.toString()} key={index}>
                    <div className="modal-video-thumbnail-container">
                      <img
                        className="pt-4 modal-video-thumbnail"
                        src={`https://discovery-journey.s3.us-east-2.amazonaws.com/${module.modulePoster}`} // Use module.modulePoster for the thumbnail image URL
                        alt={`module-video-thumbnail:${module.moduleTitle}`}
                        onClick={() => handleVideoClick(module.moduleVideo)} // Handle video click event   Use module.moduleVideo as the video URL
                      />
                      <div className="modal-video-play-button" onClick={() => handleVideoClick(module.moduleVideo)}>
                        <span>&#9654;</span>
                      </div>
                    </div>
                    <article style={{ textAlign: 'justify' }} dangerouslySetInnerHTML= {{__html: module.moduleOverview}} />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
    <Modal show={videoModalVisible} onHide={() => setVideoModalVisible(false)} size="lg">
        <Modal.Header closeButton>
          -
        </Modal.Header>
      <Modal.Body>
        <video width="100%" controls>
          <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  </div>
  );
};

export default ModalTutorial1;