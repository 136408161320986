const welcomeContent = `<div>
<p>Welcome to <strong>Plan</strong>, your ultimate tool for effectively managing your job search journey.</p>
<p>Plan is designed to seamlessly guide you from the Career Discovery phase, where you identified your career goals, passions, and unique qualifications, to the practical task of finding your dream job.</p>
<p>Plan is a comprehensive job search management tool that helps you organize and track your job search activities, including:</p>
<ul>
    <li>
        <strong>Job Tracking:</strong> Manage your job applications using a Kanban-style board, eliminating the need for spreadsheets and scattered notes. Plan simplifies the application process, keeping everything organized and accessible.
    </li>
    <li>
        <strong>Activities Management:</strong>Stay on top of your job search activities, including resume updates, cover letter writing, and interview preparation. Plan helps you create, organize, and prioritize these essential tasks.
    </li>
    <li>
        <strong>Networking:</strong>Transitioning from Discovery, where you defined your aspirations and unique qualifications, Plan empowers you to manage your networking efforts effectively. From LinkedIn connections to industry event attendance, Plan helps you build and nurture your professional network.
    </li>
    <li>
        <strong>Job Search Insights:</strong>Gain valuable insights into your job search progress, such as the number of applications sent, response rates, and interview invitations. Utilize these insights to refine your job search strategy and increase your chances of success.
    </li>
    <li>
        <strong>Customization:</strong>Tailor Plan to your unique needs by adding custom categories, labels, and notes to each job application. This flexibility allows you to organize your job search in a way that makes sense to you, enhancing your efficiency and focus.
    </li>
</ul>

<p>With Plan, you have a comprehensive toolkit at your fingertips, designed to make your job search organized, efficient, and successful. Say goodbye to the complexities of job hunting and hello to a streamlined, personalized approach. Let's embark on your journey to finding your dream job together.</p>
</div>`;


 export default welcomeContent;