import { gql } from '@apollo/client'

import { CONTACT_DETAIL, COMPANY_DETAIL, USERCONTACT_DETAIL } from './fragments'

export const QUERY_ME = gql`
  query Query {
    me {
      _id
      username
      firstName
      lastName
      email
      googleUser
      uniqueid
      profilePic
      picture
      phoneNumber
      role
      onboarding
      userboards {
        _id
        boardName
        isArchived
      }
      userfeeds {
        _id
        feedName
        feedId
        feedStatus
        emoji
        isPublic
        employeeRangeMin
        employeeRangeMax
        roles
        tags
        feedUrl
      }
      contacts {
        _id
        firstName
        lastName
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        bio
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercontacts {
        _id
        firstName
        lastName
        bio
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercompanies {
        _id
        companyName
        companyUrl
        companyLocation
        companyFoundedYear
        companyDomain
        companyDescription
        companySize
        companyCulture
        companyValues
        usercontacts {
          _id
        }
        contacts {
          _id
        }
      }
      userletters {
        _id
        letterName
        letterCategory
        letterContent
      }
      lastLoginDate
      createdDate
    }
  }
`

// V1

// export const GET_USER = gql`
//   ${CONTACT_DETAIL}
//   query ($userId: ID!) {
//     user(userId: $userId) {
//       id
//       firstName
//       lastName
//       email
//       password
//       phoneNumber
//       onboarding
//       userboards {
//         _id
//       }
//       userfeeds {
//         _id
//       }
//       usercontacts {
//         ...contactdetails
//       }
//       contacts {
//         ...contactdetails
//       }
//       userletters {
//         _id
//         letterName
//         letterCategory
//         letterContent
//       }
//     }
//   }
// `

export const GET_USER = gql`
  query Query($userId: ID) {
    user(userId: $userId) {
      _id
      username
      firstName
      lastName
      email
      googleUser
      uniqueid
      picture
      phoneNumber
      role
      onboarding
      userboards {
        _id
        boardName
        isArchived
      }
      userfeeds {
        _id
        feedName
        emoji
        isPublic
        employeeRangeMin
        employeeRangeMax
        roles
        tags
      }
      contacts {
        _id
        firstName
        lastName
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        bio
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercontacts {
        _id
        firstName
        lastName
        bio
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercompanies {
        _id
        companyName
        companyUrl
        companyLocation
        companyFoundedYear
        companyDomain
        companyDescription
        companySize
        companyCulture
        companyValues
        usercontacts {
          _id
        }
        contacts {
          _id
        }
      }
      userletters {
        _id
        letterName
        letterCategory
        letterContent
      }
      lastLoginDate
      createdDate
    }
  }
`

export const GET_ALL_USERS = gql`
  query Query {
    users {
      _id
      username
      firstName
      lastName
      email
      googleUser
      uniqueid
      picture
      phoneNumber
      role
      onboarding
      userboards {
        _id
        boardName
        isArchived
      }
      userfeeds {
        _id
        feedName
        emoji
        isPublic
        employeeRangeMin
        employeeRangeMax
        roles
        tags
      }
      contacts {
        _id
        firstName
        lastName
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        bio
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercontacts {
        _id
        firstName
        lastName
        bio
        profilePic
        gender
        title
        role
        seniority
        location
        city
        state
        country
        linkedIn
        twitter
        crunchbase
        facebook
        github
        about
        usercompanies {
          _id
        }
        companies {
          _id
        }
      }
      usercompanies {
        _id
        companyName
        companyUrl
        companyLocation
        companyFoundedYear
        companyDomain
        companyDescription
        companySize
        companyCulture
        companyValues
        usercontacts {
          _id
        }
        contacts {
          _id
        }
      }
      userletters {
        _id
        letterName
        letterCategory
        letterContent
      }
      lastLoginDate
      createdDate
    }
  }
`

export const GET_BOARD = gql`
  query Query($boardBoardId: ID!) {
    board(boardId: $boardBoardId) {
      _id
      boardName
      isArchived
      lanes {
        _id
        cards {
          _id
          actionType
          createdByWorkflow
          title
          jobLocation
          jobSalary
          jobUrl
          jobUrn
          description
          deadline
          color
          jobnote
          companyId {
            _id
          }
          usercompany {
            _id
          }
        }
        listIcon
        title
      }
      useractivity {
        _id
        activityTitle
        activitynote
        activityCategory
        activityCompleted
        startAt
        endAt
        cards {
          _id
        }
        boardId {
          _id
        }
      }
      usernote {
        _id
        noteText
      }
    }
  }
`

//get user activity from board query
export const GET_BOARD_ACTIVITY = gql`
  query Board($boardId: ID!) {
    board(boardId: $boardId) {
      _id
      boardName
      isArchived
      useractivity {
        _id
        activityTitle
        activitynote
        activityCategory
        activityCompleted
        startAt
        endAt
        cards {
          _id
          actionType
          createdByWorkflow
          title
          jobLocation
          jobSalary
          jobUrl
          jobUrn
          description
          deadline
          color
          jobnote
          usercompany {
            _id
            companyName
          }
        }
        boardId {
          _id
        }
        companyId {
          _id
        }
      }
    }
  }
`
// //get all activities for user
// export const GET_ALL_ACTIVITY = gql`
// query allActivity {
//   allActivity {
//     _id
//     activityTitle
//     activitynote
//     activityCategory
//     activityCompleted
//     startAt
//     endAt
//     cards {
//       _id
//       actionType
//       createdByWorkflow
//       title
//       jobLocation
//       jobSalary
//       jobUrl
//       jobUrn
//       description
//       deadline
//       color
//       jobnote
//     }
//     boardId {
//       _id
//       boardName
//     }
//     companyId {
//       _id
//       companyName
//     }
//     usercompany {
//       _id
//       companyName
//     }
//   }
// }
// `

//get one activity for user
export const GET_ONE_ACTIVITY = gql`
  query useractivity($useractivityId: ID!) {
    useractivity(useractivityId: $useractivityId) {
      _id
      activityTitle
      activitynote
      activityCategory
      activityCompleted
      startAt
      endAt
      cards {
        _id
        actionType
        createdByWorkflow
        title
        jobLocation
        jobSalary
        jobUrl
        jobUrn
        description
        deadline
        color
        jobnote
      }
      boardId {
        _id
        boardName
      }
      companyId {
        _id
        companyName
      }
      usercompany {
        _id
        companyName
      }
    }
  }
`

//exists for lane add mutation
export const GET_ALL_LANES = gql`
  query Query {
    alllanes {
      _id
      cards {
        _id
        title
      }
      listIcon
      title
    }
  }
`

// export const GET_CONTACTS = gql`
//   ${CONTACT_DETAIL}
//   query {
//     contacts {
//       ...contactdetails
//     }
//   }
// `
export const GET_CONTACTS = gql`
  query Query {
    contacts {
      _id
      firstName
      lastName
      profilePic
      gender
      title
      role
      seniority
      location
      city
      state
      country
      linkedIn
      twitter
      crunchbase
      facebook
      github
      bio
      about
      usercompanies {
        _id
      }
      companies {
        _id
      }
    }
  }
`

export const GET_USER_CONTACTS = gql`
  ${CONTACT_DETAIL}
  query {
    usercontacts {
      ...contactdetails
    }
  }
`

export const GET_CONTACT = gql`
  ${CONTACT_DETAIL}
  query ($contactId: ID!) {
    contact(contactId: $contactId) {
      ...contactdetails
    }
  }
`

export const GET_USERCONTACT = gql`
  ${USERCONTACT_DETAIL}
  query ($contactId: ID!) {
    mycontact(contactId: $contactId) {
      ...usercontactdetails
    }
  }
`

// export const GET_COMPANIES = gql`
//   ${COMPANY_DETAIL}
//   query {
//     companies {
//       ...companydetails
//     }
//   }
// `
export const GET_COMPANIES = gql`
  query Query {
    companies {
      _id
      companyName
      companyUrl
      companyLocation
      companyFoundedYear
      companyDomain
      companyDescription
      companySize
      companyCulture
      companyValues
      contacts {
        _id
      }
      usercontacts {
        _id
      }
    }
  }
`

export const GET_COMPANY = gql`
  ${COMPANY_DETAIL}
  query ($companyId: ID!) {
    company(companyId: $companyId) {
      ...companydetails
    }
  }
`

export const SEARCH_QUERY = gql`
  query searchQuery($search: String!) {
    searchquery(search: $search) {
      currentPage
      totalPages
      paragraphs {
        paragraphContent
        paragraphRef
      }
    }
  }
`
//Query a lane by ID
export const GET_LANE = gql`
  query Query($lanesId: ID!) {
    lanes(lanesId: $lanesId) {
      _id
      listIcon
      title
      cards {
        _id
        title
        description
        companyId {
          _id
          companyName
        }
        usercompany {
          _id
          companyName
        }
      }
    }
  }
`
//Query a lane by ID
export const GET_LANE_DATA = gql`
  query lanes($lanesId: ID!) {
    lanes(lanesId: $lanesId) {
      _id
      cards {
        _id
      }
    }
  }
`

//Query a job by ID
export const GET_USERJOB = gql`
  query userjob($userjobId: ID!) {
    userjob(userjobId: $userjobId) {
      _id
      color
      companyId {
        _id
        companyName
      }
      usercompany {
        _id
        companyName
      }
      actionType
      createdByWorkflow
      title
      jobLocation
      jobSalary
      jobUrl
      jobUrn
      description
      deadline
      jobnote
    }
  }
`

//Query a scrapejob
export const GET_SCRAPEJOB = gql`
query scrapejob($types: [String], $locations: [String], $jobTitle: String) {
  scrapejobs(types: $types, locations: $locations, jobTitle: $jobTitle) {
    id
  }
}
`

export const GET_FEEDS_BY_FEEDID = gql`
query searchfeedsbyfeedid($feedId: String!) {
  searchfeedsbyfeedid(feedId: $feedId) {
    _id
    jobkey
    company
    companyRating
    companyReviewCount
    highlyRatedEmployer
    jobLocationCity
    jobLocationPostal
    jobLocationState
    jobTitle
    keyword
    location
    maxSalary
    minSalary
    page
    position
    pubDate
    salaryType
    feedId
    tags
  }
}
`

export const GET_CITY_BY_NAME = gql`
  query GetCityByName($cityName: String!) {
    getCityByName(cityName: $cityName) {
      id
      city
      state
    }
  }
`;